import React, { useEffect } from "react";
import { InertiaLink, usePage, useForm } from "@inertiajs/inertia-react";
import { Inertia } from "@inertiajs/inertia";
const AddOutlet = (props) => {
  console.log("AddOutlet", props);

  const { data, setData, errors, post, processing } = useForm({
    type: "",
    location_id: props.props[0].id,
    location: "",
    appearance: "good",
    connections: "correct",
    tens_top: 0,
    tens_bottom: 0,
    gfci: false,
    amps: 15,
    grade: "standard",
    breaker_no: "",
    pass: true,
    tested_by: props.props.auth.user.id,
    username: props.props.auth.user.name,
  });
  function handleSubmit(e) {
    console.log("submitdata", data);
    post(route("outlet.store", data));
    setData(data);
  }

  const handleInput = (event) => {
    setData("location_id", props.props.location_id);
    setData(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
    console.log("data", data);
  };
  const handleCheck = (e) => {
    e.preventDefault();
    setData("gfci", !data.gfci);
    console.log("data", data);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className=" mb-3">
          <input
            type="text"
            className=" mb-3 form-control"
            name="location"
            placeholder="Location"
            onChange={handleInput}
            required
          />
          <div className=" mb-3 input-group">
            <input
              type="text"
              className="form-control"
              name="breaker_no"
              placeholder="Breaker Number"
              onChange={handleInput}
              required
            />
            <input
              type="text"
              className="form-control"
              name="amps"
              placeholder="Amps"
              onChange={handleInput}
              required
            />
            <input
              type="text"
              className="form-control"
              name="grade"
              placeholder="grade"
              onChange={handleInput}
            />
          </div>
          <input
            type="text"
            className="form-control mb-3"
            name="type"
            placeholder="Receptical Type"
            onChange={handleInput}
            required
          />
          <input type="hidden" vaue={data.location_id} name="location_id" />
          <input
            type="text"
            className="form-control mb-3"
            name="appearance"
            placeholder="Appearance only if other than 'good'"
            onChange={handleInput}
          />
          <input
            type="text"
            className="form-control mb-3"
            name="connections"
            placeholder="Connections only if other than 'correct'"
            onChange={handleInput}
          />
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control mb-3"
              name="tens_top"
              placeholder="Top plug tension (oz)"
              onChange={handleInput}
              required
            />
            <input
              type="text"
              className="form-control mb-3"
              name="tens_bottom"
              placeholder="Bottom plug tension (oz)"
              onChange={handleInput}
              required
            />
          </div>
          <div className="d-flex justify-content-evenly">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="gfci"
                placeholder="Top plug tension"
                onChange={handleCheck}
              />
              <label htmlFor="gfci">GFCI</label>
            </div>
            <button
              className="btn btn-success"
              onClick={(e) => {
                e.preventDefault();
                setData("pass", true);
              }}
            >
              Pass
            </button>
            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.preventDefault();
                setData("pass", false);
              }}
            >
              Fail
            </button>
          </div>
          <button type="submit" className="btn btn-outline-primary btn-sm">
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export default AddOutlet;
