import React from "react";
import { Link, useForm } from "@inertiajs/inertia-react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faToolbox,
  faBuilding,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";

const SideMenu = (props) => {
  const { data } = useForm({
    id: 1,
  });
  return (
    <ul className="nav flex-column list-group-flush">
      <div className="nav-item d-flex align-items-center flex-row list-group-item">
        <Link
          className=" fs-5 nav-link"
          aria-current="page"
          href={route("workorder.index")}
        >
          <FontAwesomeIcon icon={faToolbox} size="2x" className="me-2" />
          Workorders
        </Link>
      </div>

      <div className="nav-item d-flex align-items-center flex-row list-group-item">
        <Link className="nav-link" href={route("location.index")}>
          <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" className="me-2" />
          Building Locations
        </Link>
      </div>
    </ul>
  );
};

export default SideMenu;
