import React, { useEffect } from "react";
import { InertiaLink, usePage, useForm } from "@inertiajs/inertia-react";
import { Inertia } from "@inertiajs/inertia";
import Authenticated from "@/Layouts/Authenticated";
const EditOutlet = (props) => {
  console.log("EditOutlet", props);

  const { data, setData, errors, put, processing } = useForm({
    type: props[0].type,
    location_id: props[0].id,
    location: props[0].location,
    appearance: props[0].appearance,
    connections: props[0].connections,
    tens_top: props[0].tens_top,
    tens_bottom: props[0].tens_bottom,
    gfci: props[0].gfci,
    amps: props[0].amps,
    grade: props[0].grade,
    breaker_no: props[0].breaker_no,
    pass: props[0].pass,
    tested_by: props[0].tested_by,
  });
  function handleSubmit(e) {
    console.log("submitdata", data);
    e.preventDefault();
    put(route("outlet.update", props[0].id));
  }

  const handleInput = (event) => {
    setData("location_id", props.location_id);
    setData(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
    console.log("data", data);
  };
  const handleCheck = (e) => {
    e.preventDefault();
    setData("gfci", !data.gfci);
    console.log("data", data);
  };
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div>
          <h2>Edit outlet at {props[0].location}</h2>
          <strong className="text-danger"></strong>
        </div>
      }
    >
      <form className="edit-form">
        <div className=" mb-3">
          <input
            type="text"
            className="form-control"
            value={data.location}
            name="location"
            placeholder="Location"
            onChange={handleInput}
          />
          <div className="input-group">
            <input
              type="text"
              value={data.breaker_no}
              className="form-control"
              name="breaker_no"
              placeholder="Breaker Number"
              onChange={handleInput}
            />
            <input
              type="text
            value={data.amps}
"
              className="form-control"
              name="amps"
              placeholder="Amps"
              onChange={handleInput}
            />
            <input
              type="text"
              value={data.grade}
              className="form-control"
              name="grade"
              placeholder="grade"
              onChange={handleInput}
            />
          </div>
          <input
            type="text"
            className="form-control"
            value={data.type}
            name="type"
            placeholder="Receptical Type"
            onChange={handleInput}
          />
          <input type="hidden" vaue={data.location_id} name="location_id" />
          <input
            type="text"
            className="form-control"
            value={data.appearance}
            name="appearance"
            placeholder="Appearance only if other than 'good'"
            onChange={handleInput}
          />
          <input
            type="text"
            className="form-control"
            value={data.connections}
            name="connections"
            placeholder="Connections only if other than 'correct'"
            onChange={handleInput}
          />
          <div className="input-group">
            <input
              type="text"
              value={data.tens_top}
              className="form-control"
              name="tens_top"
              placeholder="Top plug tension (oz)"
              onChange={handleInput}
            />
            <input
              type="text"
              className="form-control"
              value={data.tens_bottom}
              name="tens_bottom"
              placeholder="Bottom plug tension (oz)"
              onChange={handleInput}
            />
          </div>
          <div className="d-flex">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value={data.gfci}
                name="gfci"
                placeholder="Top plug tension"
                onChange={handleCheck}
              />
              <label className="form-check-label" htmlFor="gfci">
                GFCI
              </label>{" "}
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name=""
                placeholder="Top plug tension"
                onChange={handleCheck}
              />
              <button
                className="btn btn-success"
                onClick={(e) => {
                  e.preventDefault();
                  setData("pass", true);
                }}
              >
                Pass
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setData("pass", false);
                }}
              >
                Fail
              </button>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            className="btn btn-outline-primary btn-sm"
          >
            Add
          </button>
        </div>
      </form>
    </Authenticated>
  );
};

export default EditOutlet;
