import React from "react";
import { Inertia } from "@inertiajs/inertia";
import { InertiaLink, usePage, useForm } from "@inertiajs/inertia-react";
import Input from "@/Components/Input";
import Authenticated from "@/Layouts/Authenticated";
import Button from "@/Components/Button";
import Checkbox from "@/Components/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRetro, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
const CreateLocation = (props) => {
  console.log("create props", props);
  const { data, setData, errors, post, processing } = useForm({
    location_name: "",
    type: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    console.log("submit data", data);
    post(route("location.store", data));
  }
  const onHandleChange = (event) => {
    setData(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
  };
  const handleRadios = (e) => {
    setData(event.target.name, event.target.value);
  };
  const handleTextarea = (e) => {
    setData("desc", e.target.value);
  };
  const handleImage = (e) => {
    console.log("cover_image", e.target.files[0]);
    setData("cover_image", e.target.files[0]);
  };
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div>
          <h2>Create New Location</h2>
          <strong className="text-danger"></strong>
        </div>
      }
    >
      <div className="card p-3">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="task">
              <span className="fs-2">Location</span>
            </label>
            <Input
              type="text"
              name="location_name"
              className="form-control"
              handleChange={onHandleChange}
              placeholder="Location"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="desc" className="l">
              <span className="fs-2">Location Type</span>
            </label>
            <Input
              type="text"
              name="type"
              className="form-control"
              handleChange={onHandleChange}
              placeholder="Location Type"
            />
          </div>
          <div className="d-flex justify-content-evenly">
            <span>
              <h3>Submit Location</h3>
            </span>
            <button className="btn  btn-outline-primary" type="submit">
              <FontAwesomeIcon icon={faPaperPlane} size="2x" />
            </button>
          </div>
        </form>
      </div>
    </Authenticated>
  );
};

export default CreateLocation;
