import React, { Component, useState } from "react";
import Authenticated from "@/Layouts/Authenticated";
import { Link } from "@inertiajs/inertia-react";
const Locations = (props) => {
  console.log("props", props);
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div>
          <h2>Locations</h2>
          <strong className="text-danger"></strong>
        </div>
      }
    >
      <ul className="list-group">
        {props[0].data.length != 0 ? (
          props[0].data.map((location) => {
            return (
              <li key={location.id} className="list-group-item">
                <Link href={route("location.show", location.id)}>
                  {location.location_name}
                  <span className="px-2">{location.type}</span>{" "}
                </Link>
              </li>
            );
          })
        ) : (
          <h1>No Locations</h1>
        )}
      </ul>
    </Authenticated>
  );
};

export default Locations;
