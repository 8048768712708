import React, { useEffect, useState } from "react";
import ApplicationLogo from "@/Components/ApplicationLogo";
import Dropdown from "@/Components/Dropdown";
import NavLink from "@/Components/NavLink";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink";
import { InertiaLink, Link, usePage } from "@inertiajs/inertia-react";
import SideMenu from "@/Components/SideMenu";
import WorkorderTable from "@/Pages/Workorders/WorkorderTable";
import UserWorkorders from "@/Pages/Workorders/UserWorkorders";
export default function Authenticated({ auth, header, children }) {
  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);
  const [currentUser, setCurrentUser] = useState(auth.user.id);
  const { url, component } = usePage();
  const [eventVisible, setEventVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  useEffect(() => {
    window.Echo.channel("workorder-created").listen("WorkorderCreated", (e) => {
      console.log("event", e);
      setEventVisible(true);
      e.workorder[0] === "Location deleted"
        ? setTypeAlert("danger")
        : setTypeAlert("success");
      setMessage(`${e.workorder[0]} By ${e.workorder[1].name} `);
    });
  }, []);
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid text-uppercase">
          <Link className="navbar-brand" href={route("workorder.index")}>
            <img
              src="/storage/site_images/Legendary_Logo.png"
              alt="legendary logo"
              height="65"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={
                    url === "/workorder" ? "active nav-link" : "nav-link"
                  }
                  aria-current="page"
                  href={route("workorder.index")}
                >
                  Work Orders
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    url === "/workorder/create" ? "active nav-link" : "nav-link"
                  }
                  href={route("workorder.create")}
                >
                  Create New Workorder
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={
                    url === "/workorder/#" ? "active nav-link" : "nav-link"
                  }
                  href="/workorder/#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {auth.user.name}
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      href={route("logout")}
                      as="button"
                      method="post"
                    >
                      Logout
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <form>
                      <input
                        className="form-control me-2"
                        type="hidden"
                        name="userPosts"
                        value={auth.user.id}
                      />

                      <button
                        type="submit"
                        className="btn btn-sm nav-link border-0 btn-outline-secondary"
                      >
                        <p className="text-muted"> My workorders </p>
                      </button>
                    </form>
                  </li>
                </ul>
              </li>
            </ul>
            <form className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                name="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-secondary " type="submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </nav>
      <div className="row mt-5">
        <div className="col-sm-3">
          <SideMenu />
        </div>
        <div className="col">
          {header && (
            <header className="">
              <div className="">{header}</div>
            </header>
          )}
          <div
            className={
              eventVisible
                ? `d-inline-block alert alert-${typeAlert} alert-dismissible fade show`
                : "d-none"
            }
            role="alert"
          >
            {message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => {
                window.location.reload();
              }}
            ></button>
          </div>{" "}
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
}
