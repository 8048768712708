import React, { Component } from "react";
import Authenticated from "@/Layouts/Authenticated";
import { Link, Head } from "@inertiajs/inertia-react";
import Pagination from "@/Components/Pagination";

const LocationTable = (props) => {
  console.log("locations props", props);
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div>
          <title>Building Locations</title>
          <h2>Locations</h2>
        </div>
      }
    >
      <Head>
        <title>Building Locations</title>
        <meta name="description" content="All recorded building locations" />
      </Head>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Location</th>
            <th scope="col">Type</th>
            <th scope="col">Breaker</th>
            <th scope="col">Location ID</th>
            <th scope="col">Connetions </th>
            <th scope="col">GFCI</th>
          </tr>
        </thead>
        <tbody>
          {props[0].length != 0 ? (
            props[0].map((l) => {
              return (
                <tr key={l.id}>
                  <td>{l.id}</td>
                  <td>{l.location}</td>
                  <td>{l.type}</td>
                  <td>{l.breaker_no}</td>
                  <td>{l.location_id}</td>
                  <td>{l.Connectons}</td>
                  <td>{l.gfci === 1 ? <p>Yes</p> : <p>No</p>}</td>
                </tr>
              );
            })
          ) : (
            <h1>No Locations</h1>
          )}
        </tbody>
      </table>
    </Authenticated>
  );
};

export default LocationTable;
