import React, { Component } from "react";
import Authenticated from "@/Layouts/Authenticated";
import { Link, Head } from "@inertiajs/inertia-react";
import Pagination from "@/Components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const Locations = (props) => {
  console.log("locations props", props);
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div>
          <title>Building Locations</title>
          <Link href={route("location.create")}>
            <FontAwesomeIcon icon={faPlusCircle} size="3x" />
          </Link>
          <h2>Locations</h2>
        </div>
      }
    >
      <Head>
        <title>Building Locations</title>
        <meta name="description" content="All recorded building locations" />
      </Head>
      <ul className="nav flex-column">
        {props[0].length != 0 ? (
          props[0].data.map((location) => {
            return (
              <li key={location.id} className="nav-item">
                <Link
                  className="nav-link"
                  href={route("location.show", location.id)}
                >
                  {location.location_name} /
                  <span className="px-2">{location.type}</span>{" "}
                </Link>
              </li>
            );
          })
        ) : (
          <h1>No Locations</h1>
        )}
      </ul>
      {props[0].links ? (
        <nav className="paginagion mt-2">
          <Pagination links={props[0].links} />{" "}
        </nav>
      ) : (
        <div></div>
      )}
    </Authenticated>
  );
};

export default Locations;
