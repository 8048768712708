import React, { useState, useEffect } from "react";
import Authenticated from "@/Layouts/Authenticated";
import { Head, Link, usePage } from "@inertiajs/inertia-react";
import Pagination from "@/Components/Pagination";

const WorkorderTable = (props) => {
  const { authenticate } = usePage().props;
  console.log("workorderTables props", props);
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={<h2>Here are your work orders {user.name}</h2>}
    >
      <table className="table">
        {!props[0] ? (
          <div>Loading</div>
        ) : (
          <div className="accordion" id="workorder-accordion">
            {props[0] ? (
              props[0].data.map((w, i) => {
                return (
                  <thead>
                    <tr>
                      <th scope="col">{w.task}</th>
                      <th scope="col">{w.desc}</th>
                      <th scope="col">{w.id}</th>
                    </tr>
                  </thead>
                );
              })
            ) : (
              <h2>No Work Orders !!</h2>
            )}
            {props[0].links ? (
              <nav className="paginagion">
                <Pagination links={props[0].links} />{" "}
              </nav>
            ) : (
              <div></div>
            )}
          </div>
        )}
      </table>
    </Authenticated>
  );
};

export default WorkorderTable;
