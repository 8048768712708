import React, {useState } from 'react';
import {Link} from '@inertiajs/inertia-react'
const Outlets = (props) => {

  console.log('outlets props', props)
  return (
    <div className="card">
      {props[0].map( (o) => {
        return <li>{o.location}
        {<Link href={route('outlet.destroy', o.id)}>DELETE!</Link>}</li>
      } )}
    </div>
  )
};

export default Outlets;
