import React, { useState, useEffect } from "react";
import Authenticated from "@/Layouts/Authenticated";
import { Inertia } from "@inertiajs/inertia";
import { InertiaLink, Link, useForm, usePage } from "@inertiajs/inertia-react";
import moment from "moment";
import LocationCommentBox from "@/Components/LocationCommentBox";
import AddOutlet from "../Outlets/AddOutlet";
import Outlets from "@/Pages/Outlets/Outlets";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChargingStation } from "@fortawesome/free-solid-svg-icons";
const Location = (props) => {
  console.log("single location", props);

  const [user_id, setUser_id] = useState(props.auth.user.id);
  const [location_id, setLocation_id] = useState(props[0].id);
  const [outletId, setOutletId] = useState(0);
  const [comment_user, setComment_user] = useState("");
  const handleDeleteOutlet = () => {};
  const handleDelete = () => {
    if (confirm("do you really want to delete this Location?")) {
      Inertia.delete(route("location.destroy", location_id));
    }
  };
  console.log(user_id, location_id);
  return (
    <Authenticated
      auth={props.auth}
      errors={props.errors}
      header={
        <div className="">
          {props.auth.user.name !== "Quinn Noldner" ? (
            <div></div>
          ) : (
            <button className="btn btn-danger" onClick={handleDelete}>
              Delete this location
            </button>
          )}
        </div>
      }
    >
      <div>
        <div className="card">
          <InertiaLink href={route("location.index")}>
            <div className="card-body">
              <span>{props.type}</span>
            </div>
          </InertiaLink>
          <div className="card-header fs-4 mx-2 border">
            <span className="px3">{props[0].location_name} </span>
            <span className="px-3">-> {props[0].type}</span>
          </div>
          <div className="card-body p-3">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <FontAwesomeIcon icon={faChargingStation} />
                    <span className="px-3">Add Outlet </span>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <AddOutlet props={props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer px-2">
            <ul className="list-group list-group flush">
              {props[0].outlet ? (
                props[0].outlet.map((c, i) => {
                  return (
                    <li key={c.id} className="list-group-item">
                      <p> Location: {c.location}</p>
                      <p>Type: {c.type}</p>
                      <p>Breaker Number: {c.breaker_no}</p>
                      <span>
                        Tested on:{" "}
                        {moment(c.created_at).format("MMM Do YYYY, h:mm a")}
                      </span>
                      <span className="px-1"> By: {c.username}</span>
                      <div>
                        {" "}
                        <span> Top Tension: {c.tens_top} </span>
                        <span> Bottom Tension: {c.tens_bottom}</span>
                      </div>

                      <p>Connections: {c.connections}</p>
                      <p>Apperance: {c.appearance}</p>
                      <p>
                        GFCI:{" "}
                        {c.gfci === 1 ? <span>YES</span> : <span>NO</span>}
                      </p>
                      <p>Grade: {c.grade}</p>
                      <p>Amps: {c.amps}</p>
                      {c.pass == 0 ? (
                        <p className="text-danger">FAILED</p>
                      ) : (
                        <p className="text-success">PASSED</p>
                      )}
                      <p>
                        Updated On:{" "}
                        {moment(c.updated_at).format("MMM Do YYYY, h:mm a")}
                        <span>By: {c.updated_by}</span>
                      </p>
                      <p>
                        {props.auth.user.name !== "Quinn Noldner" ? (
                          <div></div>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              if (
                                confirm("do you really want to delete this?")
                              ) {
                                Inertia.delete(route("outlet.destroy", c.id));
                              }
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </p>
                      <p>
                        {props.auth.user.name !== "Quinn Noldner" ? (
                          <div></div>
                        ) : (
                          <Link
                            className="btn btn-warning"
                            href={route("outlet.edit", c.id)}
                          >
                            Edit
                          </Link>
                        )}
                      </p>
                    </li>
                  );
                })
              ) : (
                <p>No Outlets recorded</p>
              )}
            </ul>
          </div>
          <div className="px-3">
            <LocationCommentBox props={{ user_id, location_id }} />
            <h3>Comments:</h3>
            <ul className="list-group list-group flush">
              {props[0].location_comment.length != 0 ? (
                props[0].location_comment.map((c, i) => {
                  return (
                    <li key={c.id} className="list-group-item">
                      {c.text}
                      <span className="px-2"></span>
                    </li>
                  );
                })
              ) : (
                <p>no comments yet</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Authenticated>
  );
};
export default Location;
